import {CooperationType, ORDER_DELIVERY_PROVIDER, ORDER_STATUS, OrderDTO, PAYMENT_TYPE} from "@dto/OrderDTO";
import {DELIVERABLE_TYPES, DELIVERY_TYPE, NON_DELIVERABLE_TYPES} from "@dto/CompanyDTO";
import {OrderDetailsDTO} from "@dto/OrderDetailsDTO";

export const isNewOrder = (order: OrderDTO | OrderDetailsDTO) => {
    return order.status === ORDER_STATUS.DISTRIBUTED
}

export const isAcceptedByCompany = (order: OrderDTO | OrderDetailsDTO) => {
    return order.status >= ORDER_STATUS.ACCEPTED_BY_COMPANY
}

export const isAcceptedByDelivery = (order: OrderDTO | OrderDetailsDTO) => {
    return order.status === ORDER_STATUS.ACCEPTED_BY_DELIVERY
}

export const isReceivedByDelivery = (order: OrderDTO | OrderDetailsDTO) => {
    return order.status === ORDER_STATUS.DELIVERY_RECEIVED
}

export const isOrderFinished = (order: OrderDTO | OrderDetailsDTO) => {
    return [ORDER_STATUS.FINISHED, ORDER_STATUS.FAILED, ORDER_STATUS.DECLINED].includes(order.status)
}

export const isOrderReady = (order?: OrderDTO | OrderDetailsDTO) => {
    if (!order) return false
    return order.preparationFinished
}

export const isOrderPickup = (order: OrderDTO) => {
    return +order.deliveryInfo.deliveryType === DELIVERY_TYPE.PICKUP
}

export const isOrderDeliverable = (order?: OrderDTO) => {
    if (!order) return false
    return DELIVERABLE_TYPES.includes(+order.deliveryInfo.deliveryType)
}

export const isOrderNonDeliverable = (order?: OrderDTO | OrderDetailsDTO) => {
    if (!order) return false
    return NON_DELIVERABLE_TYPES.includes(+order.deliveryInfo.deliveryType)
}

export const isOrderOnlinePayment = (order: OrderDTO) => {
    return order.paymentType === PAYMENT_TYPE.ONLINE
}

export const isOrderCashPayment = (order: OrderDTO) => {
    return order.paymentType === PAYMENT_TYPE.CASH
}

export const isOrderTerminalPayment = (order: OrderDTO) => {
    return order.paymentType === PAYMENT_TYPE.TERMINAL
}

export const isOrderPreorder = (order: OrderDTO) => {
    return order.preorder
}

export const isOrderInProgress = (order: OrderDTO | OrderDetailsDTO) => {
    return !order.preparationFinished && ![ORDER_STATUS.FINISHED, ORDER_STATUS.FAILED, ORDER_STATUS.DECLINED].includes(order.status)
}

export const isOrderCourierAssigned = (order: OrderDTO) => {
    if (!isOrderDeliverable(order)) return false

    if (order.status < ORDER_STATUS.ACCEPTED_BY_DELIVERY) return false

    return true
}

export const isNeedToSendToPos = (order: OrderDetailsDTO) => {
    return order && order.externalId === null
}

export const resolveOrderFullPackagePrice = (orderDetails?: OrderDetailsDTO) => {
    if (!orderDetails) return 0
    return orderDetails.items.reduce((acc, item) => {
        return acc + item.prices.packagePrice
    }, orderDetails.package?.price ?? 0)
}

export const isAsapOrder = (order: OrderDetailsDTO) => {
    return Boolean(order.deliveryInfo.isAsap)
}

export const isOrderTestOrDemo = (order: OrderDetailsDTO | OrderDTO) => {
    return order.testOrDemo || ['@demo', '@test'].includes(order.user?.name ?? '')
}

export const isNotAsapOrder = (order: OrderDetailsDTO) => !isAsapOrder(order)

export const isOrderPreparationFinished = (order: OrderDetailsDTO | OrderDTO) => order.preparationFinished

export const canIssueOrder = (order: OrderDetailsDTO|OrderDTO) => {
    if(!isOrderReady(order)) {
        return false;
    }

    if (isCompanyFinished(order)) {
        return false;
    }

    if (NON_DELIVERABLE_TYPES.includes(order.deliveryInfo.deliveryType)) {
        return true;
    }

    if (order.deliveryInfo.deliveryProviderType !== ORDER_DELIVERY_PROVIDER.DOTS) {
        return true;
    }

    if (isNonCooperationOnlyOrder(order)) {
        return false;
    }

    if (isCooperationOnlyOrder(order)) {
        return true;
    }


    return false;
}

export const canFinishOrder = (order: OrderDetailsDTO|OrderDTO) => {
    if(!isCooperationOnlyOrder(order)) {
        return false;
    }
    if(isAcceptedByDelivery(order) || isCompanyFinished(order) || isReceivedByDelivery(order)) {
        return true;
    }
    return false;
}


export const isCooperationOnlyOrder = (order: OrderDetailsDTO | OrderDTO) => order.company.cooperation === CooperationType.COOPERATION_ONLY

export const isNonCooperationOnlyOrder = (order: OrderDetailsDTO | OrderDTO) => order.company.cooperation === CooperationType.COOPERATION_ORDER_AND_DELIVERY

export const isCompanyFinished = (order: OrderDetailsDTO | OrderDTO) => order.status === ORDER_STATUS.COMPANY_FINISHED